import "./App.css";

import Navbar from "./components/Navbar";
import { Routes, Route } from "react-router-dom";
import BrowsePage from "./pages/BrowseRoute/browse";
import Article from "./pages/article/Article";
import Footer from "./components/Footer";
function App() {
  return (
    <>
      <header>
        <Navbar />
      </header>
      <main>
        <div className="content-wrapper">
        <div id="intro">
          <h1>
            Anyways<sup>©</sup>
          </h1>
        </div>
        <Routes>
          <Route path="/" element={<Article />} />
          <Route path="/browse" element={<BrowsePage />} />
          <Route path="/article/:id" element={<Article />} />
        </Routes>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default App;
