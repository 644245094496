import { Link } from "react-router-dom";

function Navbar() {
  return (
    <nav className="navbar">
      <div className="top-right-logo">
        <a href="/">
          <img src="/Images/logo1.png" alt="logo" />
        </a>
      </div>
      <div className="nav-links">
        <div>
          <Link to={"/browse"}>Browse</Link>
        </div>
        <div>
          <Link to={"/events"}>Events</Link>
        </div>
        <div>
          <Link to={"/about"}>About</Link>
        </div>
        <div className="drop-down" onMouseEnter={openDropdown}>
          <a href="/home">Dropdown</a>
          <div className="drop-down-list"></div>
        </div>
        <div>
          <Link to={"/Login"}>Login</Link>
        </div>
      </div>
    </nav>
  );
}
export default Navbar;

function openDropdown(e) {
  e.target.classList.toggle("on", true);
}
