import "./browse.css";
import PreviewCard from "./components/PreviewCard";
function BrowsePage() {
  return (
    <>
      <ul className="item-list">
        <PreviewCard title={"Darija, Moroccan Arabic, phrases & expressions"} />
        <PreviewCard />
        <PreviewCard />
        <PreviewCard />
        <PreviewCard />
       
      </ul>
    </>
  );
}
export default BrowsePage;


