export class Art{
    author: string;
    title: string;
    intro: string;
    content:string[];
    public constructor(author:string, content: string[], title:string, intro:string){
        this.intro = intro;
        this.author = author;
        this.content = content;
        this.title = title;
    }
}

export class Author{
    id:string;
    name: string;
    social_links:[{
        site_name: string;
        url: string;
    }]
    public constructor(name:string, social_links:[{site_name:string, url:string}]){
        this.name = name;
        this.social_links = social_links;
    }
}