import axios from "axios";
import { v4 as uuid } from "uuid";
import { useEffect, useState } from "react";
import { Art } from "../../models/article.ts";
function Article() {
  const [content, setContent] = useState(new Art());
  useEffect(() => {
    axios
      .get("https://apii.amantes30.com/blog/morrocan-language-post")
      .then((response) => {
        const article = new Art(
          response.data.author,
          response.data.content,
          response.data.title,
          response.data.introduction
        );
        setContent(article);
      })
      .catch((error) => console.log(error));
  }, []);
  return (
    <>
      <div id="container">
        <section className="content">
          <h1>{content.title}</h1>
          <p className="paragraph">{content.intro}</p>
          <h2>Good Luck!</h2>
          {content.content
            ? content.content.map((e) => {
                return (
                  <PhraseAndTranslation
                    key={uuid()}
                    arabic={e.arabic}
                    english={e.english}
                  />
                );
              })
            : ""}
        </section>

        <section className="author-info">
          <p>By: {content.author}</p>
          <button>Share</button>
          <ul className="social-links">
            <li>Youtube</li>
            <li>Instagram</li>
          </ul>
        </section>
      </div>
    </>
  );
}
export default Article;

function PhraseAndTranslation({ arabic, english }) {
  return (
    <p>
      {arabic} <span className="translation">- {english}</span>
    </p>
  );
}
